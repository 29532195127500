<template>
  <div class="report">
    <div class="content">
      <div class="report__caption">
        Сообщения о нежелательных явлениях и побочных эффектах
      </div>
    </div>
    <Breadcrumbs
      :pages="[
        { name: 'Сообщения о нежелательных явлениях и побочных эффектах ' },
      ]"
    />
    <div class="content">
      <div class="report__description">
        Компания «АстраЗенека» придает большое значение нашим обязательствам в отношении безопасности пациентов.
        <br />
        <br />
        Если Вам стало известно о нежелательном явлении/побочном эффекте на фоне применения лекарственного препарата «АстраЗенека», пожалуйста, сообщите об этом по следующим контактам:
        <br>
        <br>  
        <ul class="contacts__drop drop-list">
          <!-- <li class="contacts__drop drop-item">
            веб-форма по передаче нежелательных явлений <a href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/ru/ru/amp-form.html">CHAMPion</a>
          </li> -->
          <li class="contacts__drop drop-item">
            по телефону <a href="tel:84957995699*4">8 (495) 799-56-99*4</a>
          </li>
          <li class="contacts__drop drop-item">
            по электронной почте
            <a href="mailto:Safety.Russia@astrazeneca.com"
              >Safety.Russia@astrazeneca.com</a
            >
          </li>
        </ul>
        <br />
        <br />
        Пожалуйста, обратите внимание, что передавая запрос о нежелательном явлении любым из вышеуказанных способом, вы признаете, что ознакомились с условиями настоящей <a href="https://astrazeneca.ru/confidentiality.html">Политики об обработке персональных данных ООО «АстраЗенека Фармасьютикалз»</a> и согласны на обработку ваших персональных данных на указанных в ней условиях.
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
export default {
  metaInfo: {
    title: "Сообщения о нежелательных явлениях и побочных эффектах",
  },
  name: "ReportAdverseEvent",
  components: { Breadcrumbs },
};
</script>

<style lang="scss" scoped>
.report {
  padding-top: 32px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__description {
    margin-top: 64px;
    max-width: 800px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    & > a {
      color: #d0006f;
    }

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  .breadcrumbs {
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
}
</style>

<style lang="scss">
.contacts {
  &__drop {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    p {
      margin-bottom: 16px;
    }
    a {
      display: inline-block;
      color: #d0006f;
    }
    &.drop-list {
      list-style: disc;
      padding-left: 16px;
    }
    &.drop-item {
      margin-bottom: 16px;
    }
  }
}
</style>